<template>
  <main class="main article-page">
    <article class="container">
      <!--eslint-disable-->
      <h1>ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ</h1>
      <p>Индивидуальный предприниматель Борлаков Регина Руслановна (далее –
        Администрация), предлагает любому лицу (далее – Пользователь) заключить
        договор о правилах использования интернет-сайта маркетплейс Etikaego (далее –
        Соглашение) на изложенных ниже условиях.
      </p>
      <ol>
        <li><span class="marker">1.</span> ОБЩИЕ ПОЛОЖЕНИЯ
          <ol>
            <li><span class="marker">1.1.</span> В настоящем Соглашении применяются следующие термины и определения:
              Соглашение – настоящее Пользовательское соглашение.
              Сайт – совокупность программ для электронных вычислительных машин и иной
              информации, содержащейся в информационной системе, доступ к которой
              обеспечивается посредством информационно-телекоммуникационной сети
              «Интернет» по доменным именам и (или) по сетевым адресам, позволяющим
              идентифицировать сайт в сети «Интернет».
              Правообладатель – клиент Администрации, изъявивший желание посредством
              заключения договора разместить свой контент на Сайте.
              Контент – совокупность информационных, аналитических и образовательных
              материалов, а также текстов, изображений, аудио и видеозаписей, и иных
              результатов интеллектуальной деятельности в электронной форме,
              принадлежащих Правообладателю.
              Политика обработки персональных данных – документ, определяющий политику
              Администрации в области обработки персональных данных Пользователей,
              расположенный по адресу: https://etikaego.com/.
            </li>
            <li><span class="marker">1.2.</span> Данное Соглашение может содержать понятия и термины, не определенные в
              пункте 1.1. В этих случаях толкование понятий и терминов производится в
              соответствии с текстом и смыслом Соглашения. В случае отсутствия однозначного
              толкования понятия и термина в тексте Соглашения, в первую очередь следует
              руководствоваться толкованием понятий и терминов, используемых на страницах
              Сайта.
            </li>
            <li><span class="marker">1.3.</span> Сайт может использоваться Пользователем любым не запрещенным
              законодательством РФ способом, включая:
              <ul>
                <li><span class="marker">•</span> просмотр Контента с использованием Сайта;</li>
                <li><span class="marker">•</span> переход на сайт Правообладателя с целью заключения с ним сделок;</li>
                <li><span class="marker">•</span> иное использование Сайта.</li>
              </ul>
            </li>
            <li><span class="marker">1.4.</span> Использование Сайта любым способом, указанным в пункте 1.3 Соглашения
              признается и является акцептом Пользователя условий, изложенных в настоящем
              Соглашении в соответствии со статьей 438 Гражданского кодекса Российской
              Федерации (далее – ГК РФ).
            </li>
            <li><span class="marker">1.5.</span> Акцептом Соглашения Пользователь подтверждает, что:
              <ul>
                <li><span class="marker">•</span> ознакомился с условиями настоящего Соглашения и Политики обработки
                  персональных данных, размещенных на Сайте, в полном объеме до начала
                  использования Сайта;
                </li>
                <li><span class="marker">•</span> принимает все условия настоящего Соглашения и Политики обработки
                  персональных данных в полном объеме без каких-либо изъятий и
                  ограничений с его стороны и обязуется их соблюдать. Если Пользователь не
                  согласен с условиями настоящего Соглашения и/или Политики обработки
                  персональных данных или не имеет права на заключение договора на их
                  основе, ему следует незамедлительно прекратить любое использование
                  Сайта.
                </li>
                <li><span class="marker">•</span> Соглашение (в том числе любая из его частей) и/ или указанные в нем
                  Обязательные документы могут быть изменены Администрацией без какого-
                  либо специального уведомления. Новая редакция Соглашения и/или
                  указанных в нем Обязательных документов вступает в силу с момента
                  размещения на сайте Сайта либо доведения до сведения Пользователя в
                  иной удобной форме, если иное не предусмотрено новой редакцией
                  Соглашения и/или указанных в нем обязательных для Сторон документов.
                </li>
              </ul>
            </li>
            <li><span class="marker">1.6.</span> В случае несогласия Пользователя с Соглашением, либо с изменениями,
              которые были внесены Администрацией в последующем, Пользователь обязуется
              прекратить использование Сайта.
            </li>
          </ol>
        </li>
        <li><span class="marker">2.</span> ПОРЯДОК ИСПОЛЬЗОВАНИЯ САЙТА
          <ol>
            <li><span class="marker">2.1.</span> Целью Сайта является размещение Контента для информирования и
              ознакомления Пользователей для привлечения внимания Пользователя к Контенту
              и увеличения посещаемости сайта Правообладателя для заключения сделок между
              Пользователем и Правообладателем.
            </li>
            <li><span class="marker">2.2.</span> Сведения о Контенте размещаются Правообладателем самостоятельно или с
              помощью Администрации.
            </li>
            <li><span class="marker">2.3.</span> Правообладатель самостоятельно определяет условия заключения сделки с
              Пользователем. Администрация не предоставляет каких-либо гарантий, связанных
              с достижением какого-то результата при использовании Сайта, не гарантирует
              наличие какой-либо выгоды, в том числе коммерческой, достижения какого-то
              результата, либо удовлетворения иных потребностей Пользователя.
            </li>
            <li><span class="marker">2.4.</span> Доступ к Сайту предоставляется Пользователю бесплатно, если иное не
              указано на страницах Сайта.
            </li>
            <li><span class="marker">2.5.</span> Для доступа Пользователя к Сайту Администрация может применять
              технология авторизации Пользователя в Сервисе, а также для сбора
              статистических данных, в частности о посещаемости Сервиса.
            </li>
            <li><span class="marker">2.6.</span> Администрация обеспечивает круглосуточное функционирование сайта.
            </li>
            <li><span class="marker">2.7.</span> Пользователь принимает во внимание и не возражает против временной
              потери работоспособности Сайта и возможности утраты доступа к Сайту в связи с
              проведением технических работ.
            </li>
            <li><span class="marker">2.8.</span> Программно-аппаратные ошибки как на стороне Администратора, так и на
              стороне Пользователя, которые привели к невозможности получения
              Пользователем доступа к Сайту являются обстоятельствами непреодолимой силы
              и основанием освобождения Администратора от ответственности за неисполнение
              обязательств по Соглашению.
            </li>
            <li><span class="marker">2.9.</span> Администрация имеет право осуществлять контроль за Пользователем Сайта и
              пресечение его незаконных действий. Однако Администрация не гарантирует
              отсутствие на Сайте Контента не отвечающим нормам поведения и правилам
              морали и не несет ответственность за неправомерные действия Пользователя.
            </li>
            <li><span class="marker">2.10.</span> На Сайте может быть размещена реклама с согласия Администрации.
            </li>
            <li><span class="marker">2.11.</span> Пользователь может обратиться в службу технической поддержки
              Администрации для получения помощи по вопросам, связанным с использованием
              Сайта.
            </li>
            <li><span class="marker">2.12.</span> Администрация имеет право ограничить или полностью ограничить
              Пользователю доступ к Сайту или его отдельным элементам, если у нее возникнет
              подозрение или станет известно о незаконных и противоправных действиях
              Пользователя, не соответствующих условиям Соглашения или действующего
              законодательства.
            </li>
            <li><span class="marker">2.13.</span> Администрация вправе оказывать иные платные услуги, не предусмотренные
              настоящим Соглашением. Стоимость и условия предоставления платных услуг
              может быть размещена Сайте.
            </li>
          </ol>
        </li>
        <li><span class="marker">3.</span> ОБЯЗАТЕЛЬСТВА ПОЛЬЗОВАТЕЛЯ
          <ol>
            <li><span class="marker">3.1.</span> Пользователь соглашается не предпринимать действий, которые могут
              рассматриваться как нарушающие российское законодательство или нормы
              международного права, в том числе в сфере интеллектуальной собственности,
              авторских и/или смежных правах, а также любых действий, которые приводят или
              могут привести к нарушению нормальной работы Сайта и сервисов Сайта.
            </li>
            <li><span class="marker">3.2.</span> Использование материалов Сайта без согласия Правообладателей и
              Администрации не допускается (статья 1270 Г.К РФ). Для правомерного
              использования материалов Сайта необходимо заключение лицензионных
              договоров (получение лицензий) от Правообладателей и Администрации.
            </li>
            <li><span class="marker">3.3.</span> При цитировании любых материалов Сайта, включая охраняемые авторские
              произведения, ссылка на Сайт обязательна (подпункт 1 пункта 1 статьи 1274 ГК
              РФ).
            </li>
            <li><span class="marker">3.4.</span> Комментарии и иные записи Пользователя на Сайте не должны вступать в
              противоречие с требованиями законодательства Российской Федерации и
              общепринятых норм морали и нравственности.
            </li>
            <li><span class="marker">3.5.</span> Пользователь предупрежден о том, что Администрация Сайта не несет
              ответственности за посещение и использование им внешних ресурсов, ссылки на
              которые могут содержаться на сайте.
            </li>
            <li><span class="marker">3.6.</span> Пользователь согласен с тем, что Администрация Сайта не несет
              ответственности и не имеет прямых или косвенных обязательств перед
              Пользователем в связи с любыми возможными или возникшими потерями или
              убытками, связанными с любым содержанием Сайта, регистрацией авторских прав
              и сведениями о такой регистрации, товарами или услугами, доступными на или
              полученными через внешние сайты или ресурсы либо иные контакты Пользователя,
              в которые он вступил, используя размещенную на Сайте информацию или ссылки
              на внешние ресурсы.
            </li>
            <li><span class="marker">3.7.</span> Пользователь принимает положение о том, что все материалы и сервисы
              Сайта или любая их часть могут сопровождаться рекламой. Пользователь согласен
              с тем, что Администрация Сайта не несет какой-либо ответственности и не имеет
              каких-либо обязательств в связи с такой рекламой.
            </li>
          </ol>
        </li>
        <li><span class="marker">4.</span> ОТВЕТСТВЕННОСТЬ И ГАРАНТИИ
          <ol>
            <li><span class="marker">4.1.</span> Администрация не несёт ответственности за нарушение условий настоящего
              Соглашения, если такое нарушение вызвано действием обстоятельств
              непреодолимой силы (форс-мажор), включая: действия и акты органов
              государственной власти, в том числе судебные акты, запретительные акты органов
              исполнительной власти, пожар, наводнение, землетрясение, другие стихийные
              действия, отсутствие электроэнергии и/или сбои работы компьютерной сети
              (нарушение работы линий связи, неисправность оборудования и т. п.), забастовки,
              гражданские волнения, беспорядки, любые иные обстоятельства, не ограничиваясь
              перечисленным, которые могут повлиять на выполнение Администрацией условий
              настоящего Соглашения.
            </li>
            <li><span class="marker">4.2.</span> Администрация также не несёт ответственности за неисполнение или
              ненадлежащее исполнение обязательств по Соглашению, а также возможные
              убытки, возникшие в том числе, но не ограничиваясь, в результате:
              <ol>
                <li><span class="marker">4.2.1.</span> Неправомерных действий третьих лиц, направленных на нарушения
                  информационной безопасности или нормального функционирования Сайта;
                </li>
                <li><span class="marker">4.2.2.</span> Сбоев в работе Сайта, вызванных ошибками в коде, компьютерными
                  вирусами и иными посторонними фрагментами кода в программном обеспечении
                  Сайта;
                </li>
                <li><span class="marker">4.2.3.</span> Отсутствия (невозможности установления, прекращения и пр.) интернет-
                  соединений между сервером Пользователя и сервером Сайта;
                </li>
                <li><span class="marker">4.2.4.</span> Проведения государственными и муниципальными органами, а также
                  организациями мероприятий в рамках оперативно-розыскных мероприятий;
                </li>
                <li><span class="marker">4.2.5.</span> Установления государственного регулирования (или регулирования иными
                  организациями) хозяйственной деятельности коммерческих организаций в сети
                  Интернет и/или установления указанными субъектами разовых ограничений,
                  затрудняющих или делающих невозможным исполнение Соглашения или его части;
                </li>
                <li><span class="marker">4.2.6.</span> Других случаев, связанных с действиями/бездействием третьих лиц,
                  направленными на ухудшение общей ситуации с использованием сети Интернет и/
                  или компьютерного оборудования, существовавшей на момент заключения
                  Соглашения;
                </li>
                <li><span class="marker">4.2.7.</span> Выполнения Администрацией профилактических работ на Сайте.
                </li>
              </ol>
            </li>
            <li><span class="marker">4.3.</span> Все споры, вытекающие из правоотношений по настоящему Соглашению,
              разрешаются путём переговоров. В случае, если Стороны не придут к решению
              возникших между ними споров в ходе переговоров, такие споры должны быть
              переданы на рассмотрение в соответствующий суд Российской Федерации по
              месту нахождения Администрации с обязательным соблюдением претензионного
              порядка. Срок ответа на претензию составляет 2 (два) месяца с даты получения
              претензии. Претензия должна направляться в адрес Администрации по адресу,
              указанному в Едином государственном реестр юридических лиц.
            </li>
          </ol>
        </li>
        <li><span class="marker">5.</span> КОНФИДЕНЦИАЛЬНОСТЬ И БЕЗОПАСНОСТЬ
          <ol>
            <li><span class="marker">5.1.</span> Администрация сайта ответственно относится к сохранению
              неприкосновенности частной жизни и конфиденциальной информации.
              Администрация сайта хранит и обрабатывает предоставленную Пользователем
              информацию, строго придерживаясь Политики обработки персональных данных,
              размещенной на Сайте, в соответствии с требованиями ФЗ «О персональных
              данных».
            </li>
          </ol>
        </li>
        <li><span class="marker">6.</span> ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ
          <ol>
            <li><span class="marker">6.1.</span> Настоящее Соглашение регулируется и толкуется в соответствии с
              законодательством Российской Федерации. Вопросы, не урегулированные
              настоящим Соглашением, подлежат разрешению в соответствии с
              законодательством Российской Федерации. Все возможные споры, вытекающие из
              отношений, регулируемых настоящим Соглашением, разрешаются в порядке,
              установленном действующим законодательством Российской Федерации, по
              нормам российского права. Везде по тексту настоящего Соглашения, если явно не
              указано иное, под термином «законодательство» понимается законодательство
              Российской Федерации.
            </li>
            <li><span class="marker">6.2.</span> Если по тем или иным причинам одно или несколько положений настоящего
              Соглашения будут признаны недействительными или не имеющими юридической
              силы, это не оказывает влияния на действительность или применимость остальных
              положений Соглашения.
            </li>
            <li><span class="marker">6.3.</span> Бездействие со стороны Администрации в случае нарушения Пользователем
              либо иными Пользователями положений Соглашений не лишает Администрации
              права предпринять соответствующие действия в защиту своих интересов позднее,
              а также не означает отказ Администрации от своих прав в случае совершения в
              последующем подобных либо сходных нарушений.
            </li>
          </ol>
        </li>
        <li><span class="marker">7.</span> АДРЕС И РЕКВИЗИТЫ АДМИНИСТРАЦИИ
          <ul>
            <li>ИНДИВИДУАЛЬНЫЙ ПРЕДПРИНИМАТЕЛЬ
            </li>
            <li>БОРЛАКОВА РЕГИНА РУСЛАНОВНА
            </li>
            <li>ИНН 262809076353
            </li>
            <li>Расчетный счет 40802810800001145934
            </li>
            <li>ОГРН 315265100097253 Банк АО «Тинькофф Банк»
            </li>
            <li>БИК банка 044525974
            </li>
            <li>ИНН банка 7710140679
            </li>
            <li>Корреспондентский счет банка 301018101452500009749
            </li>
          </ul>
        </li>
      </ol>
      <!--eslint-enable-->
    </article>
  </main>
</template>

<script>
export default {
  name: "PrivacyPolicy",
};
</script>

<style lang="stylus">
.main.article-page {
  display: grid;
  padding: 20px 32px 100px;
  background: var(--white);

  article {
    display grid

    ul
    ol {
      margin: revert
      padding-inline-start 50px

      li {
        position relative

        .marker {
          absolute left -50px top
          width 45px
          font-weight bold
          text-align right
        }
      }
    }
  }
}
</style>
